import {observer} from 'mobx-react';
import React from 'react';
import {classNames} from '../../../../../../support/classnames';
import {ValidationMessageMap} from '../../../../../business/validation/validation_message';
import {IteratorQuestionType, NormalQuestionType} from '../../../../../enum/question_type';
import {RenderingContextType} from '../../../../../enum/rendering_context_type';
import {Appraisal} from '../../../../../models/appraisal';
import {PagePart} from '../../../../../models/page_part';
import {PagePartsSet} from '../../../../../models/page_parts_set';
import {Question} from '../../../../../models/question';
import {QuestionSet} from '../../../../../models/question_set';
import {ValidationError} from '../../../../../models/validation_error';
import {Loader} from '../../../components/loader';
import {QuestionContainer} from '../../../content/questions/question_container';
import {ConstructionDefectsWidgetContext} from '../construction_defects_widget_context';
import {ExternalAdviseBuildingInspectorPresenter} from './external_advise_building_inspector_presenter';
import {Modal} from '../../../content/questions/components/modal';
import {usePresenter} from '../../../../../../support/presenter/use_presenter';
import {QuestionRenderingData} from '../../../../../models/question_rendering_data';

interface OwnProps {
    appraisal: Appraisal;
    parentQuestion: Question;
    answerUuid: string;
    question: Question;
    questionSet: QuestionSet;
    validationErrors?: ValidationError[];
    validationMessages: ValidationMessageMap;
    forceShowValidationMessages: boolean;
    constructionCostsWidgetContext: ConstructionDefectsWidgetContext;
    onChangeConstructionCostsWidgetContext: (c: ConstructionDefectsWidgetContext) => void;
    pagePartsSet: PagePartsSet | null;
    activePagePart: PagePart | null;
    renderingContext: RenderingContextType;
    questionRenderingData: QuestionRenderingData | null;
}

export const ExternalAdviseBuildingInspector: React.FC<OwnProps> = observer(function ExternalAdviseBuildingInspector(
    props
) {
    const presenter = usePresenter((container) => {
        return new ExternalAdviseBuildingInspectorPresenter(
            props.appraisal,
            props.question,
            props.questionSet,
            props.answerUuid,
            container.network.buildingInspectorsApi,
            container.network.systemModalApi,
            container.business.answerInteractor(props.appraisal, props.questionSet),
            container.business.answerController(props.appraisal, props.questionSet),
            container.globalProvider
        );
    });

    const [didScroll, setDidScroll] = React.useState(false);

    function scrollToElement(ref: HTMLDivElement | null) {
        if (ref && !didScroll) {
            setDidScroll(true);
            ref.scrollIntoView({behavior: 'smooth'});
        }
    }

    function renderCostsAndRequest() {
        const shouldShowRequestedAdvice =
            !presenter.secondOpinionNew &&
            (props.constructionCostsWidgetContext === ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET ||
                props.constructionCostsWidgetContext === ConstructionDefectsWidgetContext.NEW_REQUEST_DEFECT_WIDGET);

        const shouldShowNewAdviceRequest =
            presenter.secondOpinionNew &&
            props.constructionCostsWidgetContext === ConstructionDefectsWidgetContext.NEW_REQUEST_DEFECT_WIDGET;

        if (shouldShowRequestedAdvice) {
            return (
                <>
                    <div className="row">
                        <div className="col-12">
                            <div className="detail-block">
                                <div className="row">
                                    <div className="col-12">
                                        <h3>
                                            <span className="c-icon-advice"></span> Second opinion
                                        </h3>
                                    </div>
                                    <div className="col-12">
                                        <p>{renderRequestIntro()}</p>
                                        {presenter.externalAdvice.externalNote && (
                                            <>
                                                <label>Advies van adviseur</label>
                                                <p>{presenter.externalAdvice.externalNote}</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            );
        } else if (shouldShowNewAdviceRequest) {
            return (
                <div className="detail-block">
                    <div className="row">
                        <div className="col-12">
                            <h3>
                                <span className="c-icon-advice"></span> Second opinion
                            </h3>
                            {renderRequestIntro()}
                        </div>
                        <div className="col-12">{renderRequestQuestion(['appraiserNote', 'photo'], 3)}</div>
                        <div className="col-12">{renderRequestMeasurement()}</div>
                        <div className="col-12">{renderRequestButtons()}</div>
                    </div>
                    <div className="spacer" ref={(ref) => ref && scrollToElement(ref)}>
                        &nbsp;
                    </div>
                </div>
            );
        }

        return null;
    }

    function renderAdvice() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <h3>
                            <span className="c-icon-advice"></span> Second opinion
                        </h3>
                        <p>
                            Een taxateur heeft gevraagd om een second opinion. Vul bovenstaand de bedragen in welke van
                            toepassing zijn op basis van de gegevens welke door taxateur zijn aangeleverd.
                        </p>
                        <div className="detail-block">
                            <div className="row">
                                <div className="col-12">
                                    <label>Maatvoering van probleem</label>
                                    <p>{presenter.externalAdvice.measurement}</p>
                                </div>
                                <div className="col-12">
                                    <label>Vraag van taxateur</label>
                                    <p>{presenter.externalAdvice.appraiserNote}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="form-group">
                            <label>Omschrijving/uitleg voor taxateur</label>
                            <div className="form-group">
                                <textarea
                                    className="form-control"
                                    rows={3}
                                    readOnly={presenter.externalNotesEnabled && !presenter.requestEnabled}
                                    value={presenter.externalAdvice.externalNote}
                                    onChange={(e) => presenter.onChangeExternalAdvice('externalNote', e.target.value)}
                                />
                                {presenter.errorMessages.map((e) => {
                                    if (e.field === 'externalNote') {
                                        return (
                                            <span key={e.field} className="invalid-feedback">
                                                {e.message}
                                            </span>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <button
                                disabled={!presenter.requestEnabled}
                                className="btn btn-primary btn-sm float-right ion-md-send"
                                onClick={() => presenter.onRequest()}
                            >
                                {presenter.busy ? <Loader isSmall={true} /> : null}
                                Verstuur kosten en (eventuele) opmerkingen
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    function renderRequest() {
        return (
            <>
                <div className="form-columns">
                    <div className="row">
                        <div className="col-12">{renderRequestIntro()}</div>
                        {presenter.shouldShowVideoNotification && (
                            <div className="col-12">
                                <div className="alert-inline alert-info">
                                    <div className="form-columns">
                                        <div className="row">
                                            <div className="col-12 col-lg-7">
                                                <div className="inline-video">
                                                    <video controls={true} autoPlay={true}>
                                                        <source
                                                            src="/images/building_inspectors_video.mp4"
                                                            type="video/mp4"
                                                        />
                                                    </video>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-5">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h3>Second opinion aanvragen</h3>
                                                        <p>
                                                            Twijfel je soms over de ernst van een gebrek of hoeveel de
                                                            herstelkosten bedragen? Bekijk de video hoe je een aanvraag
                                                            kan indienen.
                                                        </p>
                                                        <div className="spacer">&nbsp;</div>
                                                    </div>
                                                    <div className="col-12 d-flex justify-content-between">
                                                        <button
                                                            className="btn-text-link"
                                                            onClick={() => presenter.dismissNotification()}
                                                        >
                                                            Later herinneren
                                                        </button>
                                                        <button
                                                            className="btn-text-link"
                                                            onClick={() => presenter.hideNotification()}
                                                        >
                                                            Verbergen
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="form-columns">
                    <div className="row">
                        <div className="col-12 col-lg-7">{renderRequestQuestion(['appraiserNote'])}</div>
                        <div className="col-12 col-lg-5">{renderPhoto()}</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">{renderRequestMeasurement()}</div>
                </div>
                <div className="row">
                    <div className="col-12">{renderRequestButtons()}</div>
                </div>
            </>
        );
    }

    function renderRequestMeasurement() {
        return (
            <div className="form-group">
                <label>Maatvoering</label>
                <p>Geef aan hoe groot het gebrek is (vul in hoeveel vierkante meter of strekkende meter).</p>
                <input
                    type="text"
                    className="form-control"
                    disabled={presenter.appraiserNotesEnabled && !presenter.requestEnabled}
                    value={presenter.externalAdvice.measurement}
                    onChange={(e) => presenter.onChangeExternalAdvice('measurement', e.target.value)}
                />
                {presenter.errorMessages.map((e) => {
                    if (e.field === 'measurement') {
                        return (
                            <span key={e.field} className="invalid-feedback">
                                {e.message}
                            </span>
                        );
                    }
                })}
            </div>
        );
    }

    function renderNotes() {
        return (
            <div className="constructional-defects constructional-defects-notes">
                {renderRequestQuestion([NormalQuestionType.BUILDING_COSTS_PHOTO])}
            </div>
        );
    }

    function renderRequestQuestion(errorFields: string[], rows = 8) {
        return (
            <div className="form-group mt-3">
                <label>Omschrijving voor adviseur</label>
                <textarea
                    className="form-control"
                    rows={rows}
                    readOnly={presenter.appraiserNotesEnabled && !presenter.requestEnabled}
                    value={presenter.externalAdvice.appraiserNote}
                    onChange={(e) => presenter.onChangeExternalAdvice('appraiserNote', e.target.value)}
                />
                {presenter.errorMessages.map((e) => {
                    if (errorFields.includes(e.field)) {
                        return (
                            <span key={e.field} className="invalid-feedback">
                                {e.message}
                            </span>
                        );
                    }
                })}
            </div>
        );
    }

    function renderPhoto() {
        const hasPhotoIterator =
            props.questionSet.findChildByPredicateRecursive(
                props.question,
                (q) => q.type === IteratorQuestionType.PHOTO_ITERATOR_SMALL
            ) !== null;

        const hiddenQuestionTypes = [
            NormalQuestionType.DESCRIPTION,
            NormalQuestionType.ALERT_INLINE,
            NormalQuestionType.OPEN_COMPACT,
            NormalQuestionType.OPEN,
            NormalQuestionType.MC,
            NormalQuestionType.MC_SELECT,
            NormalQuestionType.MC_SELECT_OPTIONAL,
            NormalQuestionType.MC_SELECT_COMPARE,
            NormalQuestionType.MC_SELECT_DYNAMIC,
            NormalQuestionType.BOOLEAN,
            NormalQuestionType.MULTIPLE_BOOLEAN_GROUP,
        ];

        if (hasPhotoIterator) {
            hiddenQuestionTypes.push(NormalQuestionType.BUILDING_COSTS_PHOTO);
        }

        return (
            <>
                <QuestionContainer
                    key={props.question.uuid}
                    appraisal={props.appraisal}
                    question={props.question}
                    questionSet={props.questionSet}
                    parentAnswerUuid={props.answerUuid}
                    validationErrors={props.validationErrors ?? []}
                    validationMessages={props.validationMessages}
                    forceShowValidationMessages={props.forceShowValidationMessages}
                    disableAdaptedValueContainer={false}
                    hiddenQuestionTypes={hiddenQuestionTypes}
                    disabled={false}
                    pagePartsSet={props.pagePartsSet}
                    activePagePart={props.activePagePart}
                    renderingContext={props.renderingContext}
                    questionRenderingData={props.questionRenderingData}
                />
                {presenter.errorMessages.map((e) => {
                    if (e.field === 'photo') {
                        return (
                            <span key={e.field} className="invalid-feedback">
                                {e.message}
                            </span>
                        );
                    }
                })}
            </>
        );
    }

    function renderRequestButtons() {
        return (
            <>
                <button
                    disabled={
                        !presenter.requestEnabled ||
                        presenter.secondOpinionRequested ||
                        presenter.secondOpinionFinished ||
                        presenter.busy ||
                        presenter.isDisabled
                    }
                    className={classNames(
                        'btn btn-primary btn-sm float-right',
                        {'ion-md-checkmark': presenter.secondOpinionRequested},
                        {'ion-md-send': !presenter.secondOpinionRequested}
                    )}
                    onClick={() =>
                        presenter.shouldShowConfirmModal ? presenter.showConfirmModal() : presenter.onRequest()
                    }
                >
                    {presenter.busy ? <Loader isSmall={true} /> : null}
                    {presenter.secondOpinionRequested || presenter.secondOpinionFinished
                        ? ' Aanvraag is gedaan'
                        : 'Aanvragen'}
                </button>
                {renderConfirmModal()}
            </>
        );
    }

    function renderRequestIntro() {
        if (presenter.secondOpinionRequested) {
            return (
                <p>
                    Je hebt een second opinion aangevraagd bij Bouwinspecteurs voor dit aandachtspunt. Je ontvangt een
                    e-mail zodra er een advies beschikbaar is.
                </p>
            );
        } else if (presenter.secondOpinionFinished) {
            return (
                <p>
                    Je aangevraagde second opinion voor dit aandachtspunt is verwerkt door Bouwinspecteurs. Bekijk de
                    opgevoerde kosten en eventuele opmerkingen van de expert.
                </p>
            );
        }

        return (
            <p>
                Vraag een second opinion aan van Bouwinspecteurs. Stel je vraag in onderstaand formulier en verstuur
                deze om advies te krijgen over het gebrek en de (mogelijke) herstelkosten.
            </p>
        );
    }

    function renderConfirmModal() {
        return (
            <Modal visible={presenter.confirmModalVisible} onHide={() => presenter.showConfirmModal(false)}>
                <div className="modal-header">
                    <h2>Bevestig aanvraag</h2>
                </div>
                <div className="modal-body pb-0">
                    <p>
                        Door het bevestigen van deze aanvraag ga je akkoord met de{' '}
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={presenter.global.buildingInspectorInfo.termsUrl ?? ''}
                        >
                            algemene voorwaarden
                        </a>{' '}
                        van de second opinion van Bouwinspecteurs.nl.
                    </p>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-secondary" onClick={() => presenter.showConfirmModal(false)}>
                        Sluiten
                    </button>
                    <button className="btn btn-primary" onClick={() => presenter.onRequest()}>
                        Bevestigen
                    </button>
                </div>
            </Modal>
        );
    }

    switch (props.constructionCostsWidgetContext) {
        case ConstructionDefectsWidgetContext.ADVICE:
            return renderAdvice();
        case ConstructionDefectsWidgetContext.REQUEST_DEFECT_WIDGET:
        case ConstructionDefectsWidgetContext.NEW_REQUEST_DEFECT_WIDGET:
            return renderCostsAndRequest();
        case ConstructionDefectsWidgetContext.NEW_REQUEST:
            return renderRequest();
        case ConstructionDefectsWidgetContext.NOTES:
            return renderNotes();
    }

    return null;
});
